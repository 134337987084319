import { getDetailsRoute } from '../../routing/utils';
import {
  getAllConsignmentsData,
  getSummaryMetricsData,
} from '../../api/genericConsignmentView';
import {
  createRouteAll,
  createRoute,
  createRouteAllAddToTrip,
  createRouteAddToTrip,
  createManualTrip,
} from '../../api/retailDashboard';

const defaultRetailStatusFilterOptions = [
  'reachedathub',
  'outfordelivery',
  'delivered',
  'attempted',
  'inscan_at_hub',
  'in_transit',
  'pickup_awaited',
  'pickup_scheduled',
  'out_for_pickup',
  'not_picked_up',
  'pickup_completed',
  'received',
  'not_received',
  'out_for_store_pickup',
  'on_hold',
  'rescheduled',
];

const viewControl = {
  pageTitle: 'Consignments Dashboard',
  buckets: [
    'logistics_express_all',
    'logistics_express_unplanned',
    'logistics_express_planned',
    'logistics_express_in_progress',
    'logistics_express_completed',
    'logistics_express_failed',
    'logistics_express_inscan_at_hub',
    'logistics_express_bagged',
    'logistics_express_loaded',
    'logistics_express_inbound',
    'logistics_express_rto',
    'logistics_express_exception',
  ],
  navBar: {
    left: ['hub_search_with_type', 'date_filter'],
    right: ['search_redirect', 'consignment_track'],
  },
  globalActions: [],
  dateFilterOptions: [
    { key: 'created_at', val: 'created_at' },
    { key: 'last_main_event_time', val: 'last_main_event_time_retail' },
    { key: 'scheduled_at', val: 'scheduled_at' },
  ],
  searchFilterOptions: [
    { key: 'referenceNumber', val: 'consignment_reference_number' },
    { key: 'customerReferenceNumber', val: 'consignment_customer_reference_number' },
  ],
  hubSearchOptions: [{ key: 'dc', val: 'planning_hub' }],
  detailsRoute: getDetailsRoute,
  fetchData: getAllConsignmentsData,
  fetchSummaryMetrics: getSummaryMetricsData,
  defaultHubType: 'dc',
  applyDateFilterSort: true,
};
const buckets = {
  logistics_express_all: {
    name: 'All',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'addCN',
      'updateCN',
      'handlingUnits',
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
      'print_manifest_drs',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_planned: {
    hidePagination: false,
    hidePerPageCount: false,
    name: 'Planned',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'unassign',
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_unplanned: {
    hidePagination: false,
    hidePerPageCount: false,
    name: 'Unplanned',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'elevenAmFilter',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'createCustomTrip',
      'createRoute',
      'addToTrip',
      'qc_check',
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
    ],
    actionsApiCalls: {
      create_route: {
        createRouteForAll: createRouteAll,
        createRouteForSelected: createRoute,
        bulkCreateRoute: createRoute,
      },
      add_to_existing: {
        createRouteForAll: createRouteAllAddToTrip,
        createRouteForSelected: createRouteAddToTrip,
        bulkCreateRoute: createRouteAddToTrip,
      },
      create_custom_trip: {
        createCustomTrip: createManualTrip,
      },
    },
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_in_progress: {
    name: 'In Transit',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_completed: {
    name: 'Success',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'pickedup_consignment_inscan',
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
      'print_pod',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_failed: {
    name: 'Failed',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
      'moveFailedCns',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_inscan_at_hub: {
    name: 'Inscan at Hub',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'qc_check',
      'downloadHUs',
      'outscan',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_bagged: {
    name: 'Bagged',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_loaded: {
    name: 'Loaded',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'downloadHUs',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_inbound: {
    name: 'Inbound',
    filters: [
      'status',
      'attempt_count',
      'pickup_attempt_count',
      'service_type',
      'load_type',
      'customer_code',
      'consignment_type',
      'proximity',
      'orderType',
      'purchasingGroup',
      'reference_number_search',
      'customer_reference_number_search',
      'destination_pincode_city',
      'exceptions',
      'execution_status',
      'isCNVDone',
      'movementTypeList',
      'legTypeList',
      'isPaymentDone',
      'destinationHubIdList',
      'consigneeCodeList',
      'consignorCodeList',
      'nextHubIdList',
      'destinationPincodeList',
      'delivery_type',
    ],
    actions: [
      'downloadHUs',
      'inscan',
      'bulk_cn_label',
      'bulk_cn_copy_label',
      'inventory_inscan',
      'ptl_consignment_inscan',
    ],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_rto: {
    name: 'RTO',
    filters: [],
    actions: [],
    status_filter: defaultRetailStatusFilterOptions,
  },
  logistics_express_exception: {
    name: 'Exception',
    filters: [],
    actions: [],
    status_filter: defaultRetailStatusFilterOptions,
  },
};

const bucketColor = {
  logistics_express_all: '#EB2F96',
  logistics_express_planned: '#722ED1',
  logistics_express_unplanned: '#EB2F96',
  logistics_express_in_progress: '#2F54EB',
  logistics_express_completed: '#389E0D',
  logistics_express_failed: '#FA541C',
  logistics_express_inscan_at_hub: '#46CCA8',
  logistics_express_bagged: '#33E155',
  logistics_express_loaded: '#D6CB25',
  logistics_express_inbound: '#D69539',
  logistics_express_rto: '#65728D',
  logistics_express_exception: '#722ED1',
};

export default { viewControl, buckets, bucketColor };
