import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from './utils';
import { API_BASE_URL, BUSINESS_BOOKING_API_URL, LTL_BASE_URL } from '../globals';
import {
  GET_ALL_CONSIGNMENT_DATA, SEARCH_HUB,
  SEARCH_CUSTOMERS, FETCH_ONE, MARK_RTO, SET_ONHOLD, RELEASE_ONHOLD,
  RESCHEDULE_DELIVERY, UPDATE_USER_COLUMNS_OPS_V2,
  FETCH_CRM_SUMMARY_METRICS_DATA, EMAIL_CARRIER, FETCH_SUB_BUCKET_DATA,
  REQUEST_DOWNLOAD, EDIT_CONSIGNMENT, GET_DELIVERY_LOCATION, SET_DELIVERY_LOCATION,
  ADD_CONSIGNMENT, SEARCH_ACCOUNTS, GET_CONTENT_TYPE,
  INSCAN_AT_HUB, OUTSCAN_AT_HUB, DELETE_SHIPMENT, BULK_CN_UPLOAD,
  ASSIGN_TAGS, ARRIVED_IN_COUNTRY,
  ASSIGN_REMARKS_CARRIER, ASSIGN_SHIPPERS_NEXT_STEPS,
  CONSIGNEMNT_TRACKING_OPS, CONSIGNMENT_TRACKING_PRINT_OPS,
  ASSIGN_CARRIERS, CARRIER_HANDOVER,
  UPDATE_USER_TRIP_COLUMNS_OPS_V2, CONSIGNMENT_QUALITY_CHECK,
  CONSIGNMENT_QUALITY_CHECK_UPDATE, BULK_CN_UPLOAD_WITH_PIECES,
  PRINT_CONSIGNMENT_LABEL, PRINT_CN_COPY_LABEL, BULK_CN_UPLOAD_FOR_CNV,
  FETCH_COURIER_PARTNER_DETAILS, GENERATE_CARRIER_AWB_NUMBER, PICKED_UP_INSCAN_AT_HUB,
  BULK_CN_LABEL_PRINT, GET_BULK_LABEL_PRINT_REQUESTS,
  UNASSIGN_CARRIERS_THIRD_PARTY, DOWNLOAD_DISPATCH_SUMMARY, CREATE_HAND_DELIVERY,
  END_INVENTORY_SCAN, START_INVENTORY_SCAN,
  PTL_CONSIGNMENT_INSCAN,
  THIRD_PARTY_CONSIGNMENT_INSCAN, MARK_CN_ELIGIBLE_FOR_PLANNING,
  UPDATE_USER_FILTERS, ZONE_SEARCH, DOWNLOAD_CALL_LOGS, UPDATE_USER_ACTIONS, MARK_ARRIVAL,
  MARK_DELIVERED, MARK_CONSIGNMENT_AS_RTO, PICKUP_AND_DELIVERY, REVOKE_PICKUP_AND_DELIVERY,
  CONSIGNMENT_STATUS_UPDATE, GET_REVERSE_NPR_REASONS, MARK_NPR,
  RETRY_PRINT_MANIFEST,GET_MANIFEST_REQUEST_LIST,
  CREATE_MANIFEST_REQUESTS,BULK_APPROVE_REJECT_REQUESTS,FETCH_MANIFEST_BUCKETS_COUNT,
  CHECK_SHIPMENT_EXISTS,UPDATE_MANIFEST_REQUESTS,SEARCH_DOC_REF_NO,UPDATE_UPLOAD_MANIFEST,
  ARCHIVE_MANIFEST, PRINT_MANIFEST_DRS,
  FETCH_CONSOLIDATED_SUMMARY_METRICS_DATA, GET_ALL_PLANNING_DATA,
  FETCH_CONSOLIDATED_SUBBUCKET_SUMMARY_METRICS_DATA,
  MANIFEST_ERRORS,
  GET_EMPLOYEE_HUB,
  UPDATE_USER_UPDATES,
  PRINT_POD, CN_UPLOAD_REQUESTS, GET_FREE_WORKERS, GET_ALL_ZONES,
  SEARCH_HUB_WITH_REAL_FILTER, ARRIVED_AT_RETURN_HUB, FETCH_COURIER_CHANGE_REASONS_LIST,
  BULK_ASSIGN_CARRIER_SAMPLE_CSV, FETCH_CALL_LOGS, REVOKE_DELIVERY_CN_DETAILS,
  REVOKE_CANCELLATION_CN_DETAILS,
  DECRYPT_CN, FETCH_COURIER_PARTNER_DETAILS_BULK,
  UPLOAD_CONSIGNMENT_DOCUMENTS, CARRIER_BOOKING, BULK_CN_UPDATE_WITH_PIECES,
  BULK_HANDLING_UNITS_UPDATE,
} from './routes';

import { requestModelCN, responseModelCN,
  requestModelSummaryMetrics, requestModelSubBucket,
  requestModelDownloads, requestModelDownloadsLogisticsExpress, requestModelSupportPortal,
  requestModelRetailStoreCN, requestModelSummaryMetricsRetailStore,
  requestModelRetailStoreDownloads, requestModelTripColumns,
  requestModelCRMCNSearch, requestModelCNRevamp,
  requestModelManifestDeclarationCN, requestModelPlanningSummaryMetrics,
  requestModelSummaryMetricsUserChoice, requestModelPlanningSubBucketSummaryMetrics } from '../model/consignmentView';
import {
  requestModelGenericFilter,
  requestModelGenericFilterSearch,
  requestModelGenericDownload,
} from '../model/genericFilterView';
import { morphism } from 'morphism';
import { GET, POST } from './apiHandler';

const getCNbody = (body) => {
  const requestObject = {
    ...body,
    ...body.appliedFilters,
  };
  delete requestObject.appliedFilters;
  return requestObject;
};

export const getAllConsignmentsData = async(
  body,
) => {
  try {
    let requestObject = {};
    if(body.filtersRevamp){
      requestObject = getCNbody(morphism(requestModelCNRevamp, body));
    } else if (body.genericFiltersRevamp) {
      requestObject = morphism(
        body.crmCNSearch
          ? requestModelGenericFilterSearch
          : requestModelGenericFilter,
        body,
      );
    } else{
      requestObject = morphism(body.crmCNSearch ? requestModelCRMCNSearch : requestModelCN, body);
    }
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_CONSIGNMENT_DATA}`, requestObject,
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelCN, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAllConsignmentsDataStoreViewRetail = async(
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_CONSIGNMENT_DATA}`,
       { ...morphism(requestModelRetailStoreCN, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelCN, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAllConsignmentsDataForSearch = async(
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_CONSIGNMENT_DATA}`,  body ,
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelCN, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getSummaryMetricsData = async (body) => {
  try {
    const requestModel = body.isUserChoiceAllowed ? requestModelSummaryMetricsUserChoice :
                         requestModelSummaryMetrics;
    const response = await apiProvider.post(
      `${API_BASE_URL}${FETCH_CRM_SUMMARY_METRICS_DATA}`,
        morphism(requestModel, body),
        { headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getSummaryMetricsDataRetailStore = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${FETCH_CRM_SUMMARY_METRICS_DATA}`,
        morphism(requestModelSummaryMetricsRetailStore, body),
        { headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getSubBucketData = async (body) => {
  try {
    const response =
    await apiProvider.post(
      `${API_BASE_URL}${FETCH_SUB_BUCKET_DATA}`, morphism(requestModelSubBucket, body), {
        headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchHubData = async(queryString:string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_HUB}?searchQuery=${queryString}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchCustomerCode = async(queryString: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_CUSTOMERS}?searchQuery=${queryString}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchCallLogs = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_CALL_LOGS}`, params);
};

export const fetchOne = async (refNo: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_ONE}=${refNo}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data[0],
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const markRTO = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${MARK_RTO}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const setOnhold = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SET_ONHOLD}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const pendingCustom = async (
  body,
) => {
  try {
    body.status = 'pending_custom';
    body.request_type = 'single_upload';
    const response = await apiProvider.post(
      `${API_BASE_URL}${CONSIGNMENT_STATUS_UPDATE}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fulfilmentHandover = async (
  body,
) => {
  try {
    body.status = 'fulfilment_handover';
    body.request_type = 'single_upload';
    const response = await apiProvider.post(
      `${API_BASE_URL}${CONSIGNMENT_STATUS_UPDATE}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const shipsymentAsClear = async (
  body,
) => {
  try {
    body.status = 'shipment_clear_successfully';
    body.request_type = 'single_upload';
    const response = await apiProvider.post(
      `${API_BASE_URL}${CONSIGNMENT_STATUS_UPDATE}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const releaseOnhold = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RELEASE_ONHOLD}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const emailCarrier = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${LTL_BASE_URL}${EMAIL_CARRIER}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const assignTags = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${ASSIGN_TAGS}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const assignShippersNextSteps = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${ASSIGN_SHIPPERS_NEXT_STEPS}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const assignRemarksCarrier = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${ASSIGN_REMARKS_CARRIER}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const deleteShipment = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${LTL_BASE_URL}${DELETE_SHIPMENT}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const rescheduleDelivery = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RESCHEDULE_DELIVERY}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getDeliveryLocation = async (refNo: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_DELIVERY_LOCATION}?referenceNumber=${refNo}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data[0],
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const setDeliveryLocation = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SET_DELIVERY_LOCATION}`, { ...body },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const updateUserColumnList = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${UPDATE_USER_COLUMNS_OPS_V2}`, body ,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const requestDownload = async (body) => {
  let requestObject = {};

  if(body.genericFilterViewTypes) {
    requestObject = morphism(requestModelGenericDownload, body);
  } else {
    requestObject = morphism(requestModelDownloads, body);
  }
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${REQUEST_DOWNLOAD}`, requestObject,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const requestDownloadRetailStore = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${REQUEST_DOWNLOAD}`, morphism(requestModelRetailStoreDownloads, body),
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const editConsignment = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${EDIT_CONSIGNMENT}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchAccounts = async(body) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_ACCOUNTS}${body}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data : response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getContentType = async() => {
  try {
    const response = await apiProvider.get(
      `${BUSINESS_BOOKING_API_URL}${GET_CONTENT_TYPE}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data : response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const addConsignment = async(body) => {
  let apiObject:any = {
    customerReferenceNumber: body.customer_reference_number,
    referenceNumber: body.reference_number,
    courierType: body.load_type,
    consignmentType: body.consignment_type,
    srcAddress: {
      pincode: body.origin_details.pincode,
      name: body.origin_details.name,
      phone: body.origin_details.phone,
      addressLine1: body.origin_details.address_line_1,
      addressLine2: body.origin_details.address_line_2,
      cityName: body.origin_details.city,
      stateName: body.origin_details.state,
      countryName: body.origin_details.country,
    },
    dstAddress: {
      pincode: body.destination_details.pincode,
      name: body.destination_details.name,
      phone: body.destination_details.phone,
      addressLine1: body.destination_details.address_line_1,
      addressLine2: body.destination_details.address_line_2,
      cityName: body.destination_details.city,
      stateName: body.destination_details.state,
      countryName: body.destination_details.country,
    },
    weight: body.weight,
    serviceType: body.service_type_id,
    isRiskSurchargeApplicable: body.is_risk_surcharge_applicable,
    commodityId: body.commodity_id,
    codCollectionMode: body.cod_collection_mode,
    codFavorOf: body.cod_favor_of,
    codAmount: body.cod_amount,
    description: body.description,
    deliveryCharge: body.delivery_charge,
    customerId: body.customer_id,
    numberOfPieces: body.num_pieces,
    declaredPrice: body.declared_value,
  };
  if (body.length || body.width || body.height) {
    apiObject = {
      ...apiObject,
      dimensions:{
        length:body.length,
        width:body.width,
        height:body.height,
      },
    };
  }
  try {
    const response = await apiProvider.post(
      `${BUSINESS_BOOKING_API_URL}${ADD_CONSIGNMENT}`,  apiObject,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const inscanAtHub = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${INSCAN_AT_HUB}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const outscanAtHub = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${OUTSCAN_AT_HUB}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const uploadMultipleCN = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${BULK_CN_UPLOAD}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    // if (data.failures) {
    //   data.failures = data.failures.map(ele =>
    //     ({ message: ele.error, reference_number: ele.reference_number }),
    //   );
    // }
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSucces: false,
    };
  }
};

export const getAllConsignmentsDataSupportPortal = async(
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_CONSIGNMENT_DATA}`,
       { ...morphism(requestModelSupportPortal, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelCN, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getConsignmentEvents = async(referenceNo) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${CONSIGNEMNT_TRACKING_OPS}?reference_number=${referenceNo}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const downloadCNHistory = async(referenceNo) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${CONSIGNMENT_TRACKING_PRINT_OPS}?reference_number=${referenceNo}`,
      {
        headers: await getAuthenticationHeaders(),
        responseType: 'blob',
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const qualityCheckforCN = async(params) => {
  return GET(`${API_BASE_URL}${CONSIGNMENT_QUALITY_CHECK}`, params );
};
export const updateQcCheck = async(body) => {
  return POST(`${API_BASE_URL}${CONSIGNMENT_QUALITY_CHECK_UPDATE}`, body );
};

export const assignCarriers = async (body) => POST(`${API_BASE_URL}${ASSIGN_CARRIERS}`, body);
export const carrierHandover = async (body) => POST(`${API_BASE_URL}${CARRIER_HANDOVER}`, body);
export const carrierBooking = async (body) => POST(`${API_BASE_URL}${CARRIER_BOOKING}`, body);

export const getCourierPartnerDetails = async(params) => GET(`${API_BASE_URL}${FETCH_COURIER_PARTNER_DETAILS}`, params);
export const getCourierPartnerDetailsBulk = async(params) => GET(`${API_BASE_URL}${FETCH_COURIER_PARTNER_DETAILS_BULK}`, params);
export const generateCarrierAWBNo = async (body) => {
  const newBody = {
    ...body,
  };
  newBody.consignments = newBody.consignments?.map(cn => ({
    ...cn,
    courier_partner: cn.courier_partner || 'SELF',
  }));
  return POST(`${API_BASE_URL}${GENERATE_CARRIER_AWB_NUMBER}`, newBody);
};
export const fetchCourierChangeReasonsList = async(params) => GET(`${API_BASE_URL}${FETCH_COURIER_CHANGE_REASONS_LIST}`, params);
export const bulkAssignCarriersSampleCSV = async() => GET(`${API_BASE_URL}${BULK_ASSIGN_CARRIER_SAMPLE_CSV}`);

export const updateUserTripColumnList = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_USER_TRIP_COLUMNS_OPS_V2}`, morphism(requestModelTripColumns, body));
};

export const uploadMultipleCnsWithPieces = async(body) => {
  return POST(`${API_BASE_URL}${BULK_CN_UPLOAD_WITH_PIECES}`, body );
};

export const printConsignmentLabel = async(body) => {
  return POST(`${API_BASE_URL}${PRINT_CONSIGNMENT_LABEL}`, body, undefined, true );
};

export const printCNCopyLabel = async(params) => {
  return GET(`${API_BASE_URL}${PRINT_CN_COPY_LABEL}`, params, 'blob');
};
export const uploadMultipleCNsForCNV = async(body) => {
  return POST(`${API_BASE_URL}${BULK_CN_UPLOAD_FOR_CNV}`, body );
};
export const pickedUpInscanAtHub = async(body) => {
  return POST(`${API_BASE_URL}${PICKED_UP_INSCAN_AT_HUB}`, body );
};
export const bulkCNLabelPrint = async(body) => {
  return POST(`${API_BASE_URL}${BULK_CN_LABEL_PRINT}`, body );
};
export const getAllBulkCNLabelPrintRequests = async(params) => GET(`${API_BASE_URL}${GET_BULK_LABEL_PRINT_REQUESTS}`, params);
export const unassignCarriers = async(body) => {
  return POST(`${API_BASE_URL}${UNASSIGN_CARRIERS_THIRD_PARTY}`, body );
};

export const downloadDispatchSummary = async(body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_DISPATCH_SUMMARY}`, body, await getAuthenticationHeaders(), true);
};

export const createHandDelivery = async(body) => {
  return POST(`${API_BASE_URL}${CREATE_HAND_DELIVERY}`, body );
};
export const startInventoryScan = async(body) => {
  return POST(`${API_BASE_URL}${START_INVENTORY_SCAN}`, body );
};
export const endInventoryScan = async(body) => {
  return POST(`${API_BASE_URL}${END_INVENTORY_SCAN}`, body );
};
export const ptlConsignmentInscan = async(body) => {
  return POST(`${API_BASE_URL}${PTL_CONSIGNMENT_INSCAN}`, body );
};
export const thirdPartyConsignmentInscan = async(body) => {
  return POST(`${API_BASE_URL}${THIRD_PARTY_CONSIGNMENT_INSCAN}`, body );
};
export const markCnEligibleForPlanning = async(body) => {
  return POST(`${API_BASE_URL}${MARK_CN_ELIGIBLE_FOR_PLANNING}`, body);
};

export const retryPrintManifest = async (body) => {
  return POST(`${API_BASE_URL}${RETRY_PRINT_MANIFEST}`, body);
};

export const updateUserFilters = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_USER_FILTERS}`, body);
};
export const updateUserUpdates = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_USER_UPDATES}`, body);
};
export const searchZone = async(params) => {
  return GET(`${API_BASE_URL}${ZONE_SEARCH}`, params);
};

export const downloadCallLogs = async(body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_CALL_LOGS}`, morphism(requestModelDownloads, body));
};

export const downloadCallLogsBulk = async(body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_CALL_LOGS}`, body);
};
export const updateUserActions = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_USER_ACTIONS}`, body);
};
export const markArrival = async(body) => {
  return POST(`${API_BASE_URL}${MARK_ARRIVAL}`, body);
};
export const markDelivered = async(body) => {
  return POST(`${API_BASE_URL}${MARK_DELIVERED}`, body);
};
export const markConsignmentAsRTO = async(body) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_AS_RTO}`, body);
};
export const markPickupAndDelivery = async(body) => {
  return POST(`${API_BASE_URL}${PICKUP_AND_DELIVERY}`, body);
};
export const revokePickupAndDelivery = async(body) => {
  return POST(`${API_BASE_URL}${REVOKE_PICKUP_AND_DELIVERY}`, body);
};
export const consignmentStatusUpdate = async(body) => {
  return POST(`${API_BASE_URL}${CONSIGNMENT_STATUS_UPDATE}`, body);
};
export const getReverseNprReasons = async(body) => {
  return POST(`${API_BASE_URL}${GET_REVERSE_NPR_REASONS}`, body);
};
export const markNpr = async(body) => {
  return POST(`${API_BASE_URL}${MARK_NPR}`, body);
};
export const fetchManifestRequestList = async(body) => {
  const params = morphism(requestModelManifestDeclarationCN, body);
  const response = await POST(
    `${API_BASE_URL}${GET_MANIFEST_REQUEST_LIST}`,
    params,
  );
  if (response.isSuccess) {
    return {
      data: morphism(responseModelCN, response.data ),
      isSuccess: true,
    };
  }
  return response;
};
export const createManifestRequest = async(body) => {
  return POST(`${API_BASE_URL}${CREATE_MANIFEST_REQUESTS}`,body);
};
export const updateManifestRequest = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_MANIFEST_REQUESTS}`,body);
};
export const bulkApproveRejectRequest = async(body) => {
  return POST(`${API_BASE_URL}${BULK_APPROVE_REJECT_REQUESTS}`,body);
};
export const fetchSummaryMetricsForManifest = async(body) => {
  return POST(`${API_BASE_URL}${FETCH_MANIFEST_BUCKETS_COUNT}`,morphism(requestModelPlanningSummaryMetrics, body));
};
export const searchAWBForManifest = async(params) => {
  return GET(`${API_BASE_URL}${CHECK_SHIPMENT_EXISTS}`,params);
};
export const searchDocReferenceNumber = async(params) => {
  return GET(`${API_BASE_URL}${SEARCH_DOC_REF_NO}`,params);
};
export const uploadUpdateManifest = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_UPLOAD_MANIFEST}`,body);
};
export const archiveManifest = async(body) => {
  return POST(`${API_BASE_URL}${ARCHIVE_MANIFEST}`,body);
};
export const manifestErros = async(body) => {
  return POST(`${API_BASE_URL}${MANIFEST_ERRORS}`,body);
};
export const printManifestDRS = async(body) => {
  return POST(`${API_BASE_URL}${PRINT_MANIFEST_DRS}`, body, undefined, true );
};
export const printPOD = async(body) => {
  return POST(`${API_BASE_URL}${PRINT_POD}`, body, undefined, true );
};
export const getEmployeeHub = async(params: {
  hubType: string;
  realFilter: boolean;
}) => {
  return GET(`${API_BASE_URL}${GET_EMPLOYEE_HUB}`,params);
};
export const getCNUploadRequests = async(params) => {
  return GET(`${API_BASE_URL}${CN_UPLOAD_REQUESTS}`,params);
};

export const requestDownloadLogisticsExpress = async(body) => {
  return POST(`${API_BASE_URL}${REQUEST_DOWNLOAD}`,morphism(requestModelDownloadsLogisticsExpress, body));
};
export const getAllPlanningData = async(body) => {
  const params = morphism(requestModelGenericFilter, body);
  const response = await POST(
    `${API_BASE_URL}${GET_ALL_PLANNING_DATA}`,
    params,
  );
  if (response.isSuccess) {
    return {
      data: morphism(responseModelCN, response.data ),
      isSuccess: true,
    };
  }
  return response;
};
export const getConsolidatedPlanningSummaryMetricsData = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_CONSOLIDATED_SUMMARY_METRICS_DATA}`,morphism(requestModelPlanningSummaryMetrics, body));
};
export const getPlanningSubBucketSummaryData = async(params) => {
  return POST(`${API_BASE_URL}${FETCH_CONSOLIDATED_SUBBUCKET_SUMMARY_METRICS_DATA}`,morphism(requestModelPlanningSubBucketSummaryMetrics, params));
};

export const arrivedInCountry = async(params) => {
  return POST(`${API_BASE_URL}${ARRIVED_IN_COUNTRY}`,params);
};

export const getAllWorkers = async(params) => {
  const response = await GET(
    `${API_BASE_URL}${GET_FREE_WORKERS}`,params,
  );
  return {
    data: response.data,
    isSuccess:true,
    errorMessage: response.errorMessage,
  };
};

export const getAllZones = async(hubId) => {
  const response = await GET(
    `${API_BASE_URL}${GET_ALL_ZONES(hubId)}`,
  );

  return {
    data: response.data?.zones || [],
    isSuccess: true,
  };
};

export const getAllPlanningHubs = async(params) => {
  return GET(
    `${API_BASE_URL}${SEARCH_HUB_WITH_REAL_FILTER}`, params,
  );
};

export const arrivedAtReturnHub = async(params) => {
  return POST(`${API_BASE_URL}${ARRIVED_AT_RETURN_HUB}`,params);
};

export const revokeDeliveryCNDetails = async(
  body,
) => {
  const response = await POST(
      `${API_BASE_URL}${REVOKE_DELIVERY_CN_DETAILS}`,  body,
  );
  return response?.data || response;
};

export const revokeCancellationCNDetails = async(
  body,
  ) => {
    const response = await POST(
      `${API_BASE_URL}${REVOKE_CANCELLATION_CN_DETAILS}`,  body,
    );
    return response?.data || response;
};

export const decryptConsignment = async (params) => {
  return GET(`${API_BASE_URL}${DECRYPT_CN}`, params);
};

export const uploadConsignmentDocuments = async (params) => {
  return POST(`${API_BASE_URL}${UPLOAD_CONSIGNMENT_DOCUMENTS}`, params);
};

export const updateMultipleCnsWithPieces = async (body) => {
  return POST(`${API_BASE_URL}${BULK_CN_UPDATE_WITH_PIECES}`, body);
};

export const updateHandlingUnits = async (body) => {
  return POST(`${API_BASE_URL}${BULK_HANDLING_UNITS_UPDATE}`, body);
};

export const revokeDeliveryCn = async (body) => {
  return POST(`${API_BASE_URL}${REVOKE_DELIVERY_CN_DETAILS}`, body);
};
